:root {
  --dark-gradient: linear-gradient(0deg, #2C3290, #161B6F);
  --light-gradient: linear-gradient(0deg, #962734, #FC4A60);
  --dark-color: #2C3290;
  --light-color: #FFCC00;
  --border-color: #EC1C25;
}
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 
@font-face {
  font-family: 'ITC Bookman Std';
  src: url('/public/font/Bookman-ITC-Medium.otf') format('opentype'); /* Format for .otf is 'opentype' */
  font-weight: normal;
  font-style: normal;
}
body, html{
  font-family: "Be Vietnam Pro", serif;
}
h3, h1, h2, h5, h6{
  font-family: 'ITC Bookman Std';
}
p{
  font-size: 15px;
}
  .header {
    border-bottom: 4px solid var(--dark-color);
}
a:hover{
  color: inherit;
}
.logo img {
  width: 77%;
  margin: 0px auto;
  display: block;
}
.campuslogo {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
} 
.col-xl-3.col-lg-hide.d-flex.p-0 {
  align-items: center;
  justify-content: stretch;
}

.slider-sec div#carouselExampleCaptions img {
  border-bottom: 4px solid var(--dark-color);
}
 
nav.navbar {
  background: #fffffff2;
  padding: 15px;
  width: 95%;
  border-radius: 30px 30px 30px 3px;
  float: left;
  margin-top: 11px;
  position: relative;
  z-index: 9;
  width: 77%;
} 
nav.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
} 
nav.navbar ul.navbar-nav li {
  text-transform: uppercase;
}

.header-contact img {
  width: 32px;
}
.nav-link{
  color: #333333;
}
.col-xl-4.header-cont {
  position: relative;
  overflow: hidden;
}
button.header-btn{
  position: relative;
  overflow: hidden;
  border: 0px;
  margin: 0 8px 0 0px;
  padding: 5px 23px;
  min-width: 190px;
  background: transparent;
  font-weight: 300;
}
button.header-btn:before{
  content: "";
  width: 78%;
  height: 170px;
  background: var(--dark-gradient);
  position: absolute;
  right: 11px;
  top: -54px;
  transform: rotate(332deg);
  border: 3px solid var(--light-color);

}
button.header-btn span{
  position: relative;
  color: #fff;
  font-size: 14px;
}
.header-contact:before {
  content: "";
  width: 105%;
  height: 460px;
  background: var(--dark-gradient);
  position: absolute;
  right: 0px;
  top: -130px;
  transform: rotate(338deg);
}

.header-contact p {
  position: relative;
  margin-bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.header-nav {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  justify-content: flex-end;
}

.header-nav .online-fee button {
  min-width: max-content;
  padding: 9px 14px;
  background: var(--light-color);
  border: 0;
  border-left: 3px solid var(--border-color);
  border-right: 3px solid var(--border-color);
  font-style: italic;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 14px;
}
.highlights p {
  margin-bottom: 0;
  color: #000;
  padding-top: 4px;
}

a {
  text-decoration: none;
  color: inherit;
}

.highlights:before {
  content: "";
  width: 89%;
  height: 396px;
  background: #D1D0D0;
  position: absolute;
  right: 46px;
  top: -183px;
  transform: rotate(336deg);
}

.highlights {
  overflow: hidden;
  position: relative;
}
.highlights marquee p {
  display: inline-block;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%;}
@keyframes bounce1 { 
  0% {
    background-color: #2c3290;
}

33% {
    background-color: #ffcc00;
}
66% {
    background-color: #2c3290;
}
100% {
    background-color: #ffcc00;
}
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }
.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/ABSB/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/ABSB/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: #131643; border: #131643; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #131643; }
.carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(0deg,#131643,#f56c6c);
}
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #131643;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/ABSB/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
.whatsappicn {
  position: fixed;
  bottom: 15px;
  z-index: 9;
  left: 4px;
}

.whatsappicn img {
  width: 60px;
}
.aboutmidsec {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.aboutmidsec .box {
  background: linear-gradient(180deg, #2C3290, #161B6F);
  margin: -110px 10px 0;
  width: 310px;
  text-align: center;
  position: relative;
}
.aboutmidsec .box:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/shadow.png);
  background-size: 100% 100%;
  z-index: -1;
}

.aboutmidsec .box h3 {
  background: var(--light-color);
  text-transform: uppercase;
  padding: 7px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2px;
}

.aboutmidsec .box p {
  padding: 10px 19px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
}
.about{
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/about-bg.jpg);
  background-size: 100% 100%;
  background-position: center;
  padding: 60px 0;
}


.title p {
  font-style: italic;
  margin-bottom: 0;
  color: #333333;
}

.title h1 , .title h2{
  color: var(--dark-color);
  font-size: 31px;
  text-transform: uppercase;
  font-weight: 600;
}

button.readmore {
  background: linear-gradient(180deg, #2C3290, #161B6F);
  border: 0px;
  border-left: 3px solid var(--light-color);
  border-right: 3px solid var(--light-color);
  color: #fff;
  padding: 9px 22px;
  font-style: italic;
  font-weight: 300;
  margin-top: 11px;
}
.message-container {
  display: flex;
}

.message-container .messageimg {
  width: 30%;
  margin-right: 15px;
}
.message-container .messageimg p {
  font-weight: 800;
}

.message-container .messageimg p span {
  font-weight: 400;
  font-style: italic;
  display: block;
}

.message-container .messagebox {
  width: 70%;
}

.message-container .messageimg img {
  width: 100%;
  border-radius: 34px;
  margin: auto;
  display: block;
  height: 240px;
}
.messagebox .maintitle{
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/strip.png);
  background-size: 100% 100%;
  width: max-content;
  padding: 4px 41px 0px 18px;
  margin-bottom: 22px;
} 

.messagebox .maintitle h3 {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 0;
}
.messagedesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.message-blk{
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/message-bg.jpg);
  background-size: 100% 100%;
  padding: 30px 30px 52px;
  width: 95%;
  height: 500px;
}
.news ul {
  list-style: none;
  padding: 0;
}

.news ul li p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0;
}
.newsdesc a {
  color: #1c2178;
}

.newsdesc {
  width: 100%;
}
.news ul li h6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news ul li img {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
} 

.news .newsbox {
  align-items: flex-start;
  display: flex;
  margin-bottom: 10px;
}

.news .newsbox .news-date span {
  display: block;
}

.news .newsbox .news-date {
  text-align: center;
  background: var(--light-color);
  padding: 7px 10px;
  border-radius: 7px;
  font-size: 13px;
  line-height: 16px;
  min-width: 48px;
  max-width: 48px;
  width: 48px;
}
.news {
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/news-bg.png);
  background-size: 100% 100%;
  padding: 33px 33px 60px;
} 

.newsbg marquee {
    height: 270px;
}
.message{
  position: relative;
}
.msg-anim1{
  position: absolute;
  left: 7%;
  top: 18%;
  width: 67px;
}
.msg-anim2{
  position: absolute;
  left: 3%;
  bottom: 20%;
  width: 100px;
  opacity: 0.3;
}
.msg-anim3{
  position: absolute;
  right: 3%;
  top: 10%;
  width: 100px;
  opacity: 0.3;
  transform: rotate(330deg);
}
.msg-anim4{
  position: absolute;
  right: 20px;
  bottom: 28%;
  width: 120px;
  opacity: 0.3;
  transform: rotateZ(23deg);
}
.homeeventbox {
  background: #EBD270;
  width: 95%;
  border-radius: 18px;
  padding: 10px;
}
.homeeventboximg img {
  border-radius: 15px;
  width: 100%;
  height: 200px;
  object-fit: contain;
  background: #f1f1f1;
}
.homeeventbox h3 {
  font-size: 19px;
  color: var(--dark-color);
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.date {
  margin: -16px auto 10px;
  display: block;
  width: max-content;
  background: var(--light-color);
  z-index: 9;
  position: relative;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 11px;
} 

.homeeventbox  p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.homeeventbox .d-flex.justify-space-between {
  justify-content: space-evenly;
  color: #fff;
}

.homeeventbox  .d-flex.justify-space-between p {
  background: var(--dark-gradient);
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 9px;
  font-style: italic;
}
.title h2 {
  font-size: 25px;
}
.toppertaj{
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/topper-img-bg.png);
  background-size: 100% 100%;
} 
.toppertaj {
  padding-top: 50px;
  width: 200px;
  height: 176px;
  margin: auto;
}

.toppertaj img {
  margin: auto;
  border-radius: 50%;
  width: 105px;
  height: 105px;
  object-fit: fill;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 0px 3px #f16646;
}

.topperdiv {
  text-align: center;
}
.topperdesc h6{
  font-size: 18px;
  font-weight: 800;
  color: var(--dark-color);
}
.topperdesc .name{
  font-weight: 600;
  margin-bottom: 0;
}
.topperdesc .class{
  margin: 20px auto;
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/class-bg.png);
  background-repeat: no-repeat;
  width: max-content;
  background-size: 100% 100%;
  min-width: 70px;
  color: #fff;
}

.galleryimgbox img {
  border: 9px solid #EBD270;
  margin-bottom: 16px;
  box-shadow: 0px 20px 19px -19px #7c7c7c;
  width: 100%;
  height: 200px;
    object-fit: contain;
    background: #f1f1f1;
}

.galleryimg {
  width: 90%;
  margin: auto;
}

.gallerydesc {
  text-align: center;
}

.gallerydesc h6 {
  text-transform: uppercase;
  font-weight: 800;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
}
.gallerydesc p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
footer{
  background: url(https://webapi.entab.info/api/image/ABSB/public/Images/footer-bg.png) no-repeat;
  background-size: 100%  auto;
  padding:80px 20px 20px;
  margin-top: 40px;
  position: relative;
}
footer:before{ 
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, #161c70 79%, transparent 10%);
    z-index: -1;
}
footer .d-flex li img {
  width: 27px;
}

footer .d-flex ul {
  list-style: none;
  padding: 0;
  color: #fff;
  font-size: 14px;
}

footer .d-flex {
  justify-content: space-around;
  margin-top: 40px;
  color: #fff;
}

footer .d-flex h5 {
  color: #fff;
 text-transform: uppercase;
 font-size: 18px;
}

.copyright {
  text-align: center;
  font-size: 14px;
  padding: 5px;
}

.copyright a {
  color: var(--border-color);
  font-weight: 600;
}
.ftr-logo p {
  color: #fff;
  text-align: center;
  margin-top: 15px;
}

.ftr-logo img {
  margin: auto;
  display: block;
}
.ftr-cont img{
  width: 50px;
}
.mobile-app {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.mobile-app h3{
  font-size: 20px;
  text-transform: uppercase;
}
.mobile-app .mobile-link img {
  margin: 5px 10px;
}
.mobile-app .mobile-link {
  display: flex;
}

p.code {
  width: max-content;
  background: linear-gradient(90deg, #469cff, #004390);
  color: #fff;
  padding: 0px 7px;
  margin-top: 8px;
  border-radius: 6px;
}

.ftr-cont.d-flex {
  justify-content: flex-start;
}

.ftr-cont.d-flex p {
  margin-bottom: 5px;
  font-size:14px;
}
.mobile-app p{
  font-size: 14px;
  max-width: 270px;
}
.padding-section{
  padding: 40px 0;
}
button.readmore:hover {
  background: var(--light-gradient);
}
.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%),url('https://webapi.entab.info/api/image/ABSB/public/Images/innersld.jpg') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #ef3436;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}
.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
}
section.innerbody h1,  section.innerbody h2,section.innerbody h3,
section.innerbody h4,section.innerbody h5,section.innerbody h6 ,
section.innerbody p, section.innerbody li{
font-family: Roboto Slab,serif;
}
.innerbody h5 {
  color: #0664a8;
  font-size: 20px;
  line-height: 30px;
}
.missionaim h4 {
  background: linear-gradient(87deg,#29559838,#0000);
  font-family: cursive;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px;
  padding: 15px 20px;
  width: 100%;
}
.ethos {
  background: linear-gradient(45deg, #c1c4e7, #03adef30);
  padding: 20px 50px;
  text-align: center;
  outline: 1px dashed #000;
  outline-offset: -8px;
  border-radius: 4px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
ul.dropdown-menu.fade-up.show {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background: #f1f1f1;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 0px 10px 2px #0d0d0d30;
}
ul.dropdown-menu.fade-up.show a {
  text-transform: capitalize;
  padding: 10px 10px;
}
ul.dropdown-menu .dropdown-item.active {
  background: #232882;
}
ul.dropdown-menu.fade-up li {
  border-bottom: 1px solid #ccc;
}
section.innerbody {
  padding: 60px 0;
  min-height: 350px;
}

.event-blk {
  margin-bottom: 20px;
}
.inr-event-blk {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 25%);
}

.inr-event-img { 
  width: 250px;  
  margin-right: 5px; 
  min-width: 250px;
}

.inr-event-img img {   
  object-fit: contain;
  background: #f1f1f1;
  padding-left: 0px;
  height: 200px;
  width: 100%;
}

.inr-event-content {
  overflow: hidden;
  padding: 10px 15px;
  word-break: break-word;
  position: relative;
  height: 200px;
  overflow-y: auto;
  width: 100%;
}
.inr-event-content::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.inr-event-content::-webkit-scrollbar-thumb {
  background-color: #00adef;
}
.inr-event-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}

.inr-event-content:before {
  display: none;
  position: absolute;
  content: "";
  height: 100%;
  width: 98%;
  background: linear-gradient(45deg, #eddfeb, #fff4da);
  transform: skewX(70deg);
  top: 0;
  left: 0;
}

.inr-event-content h6.date {
  font-size: 13px;
  line-height: 22px;
  color: #ea1f17;
  margin-bottom: 8px !important;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.inr-event-content h3 {
  color: #232323;
  font-size: 16px;
  line-height: 28px;
  display: block;
  margin-bottom: 5px !important;
  font-weight: 600;
  position: relative;
  margin: 0;
  text-transform: uppercase;
}

.inr-event-content p {
  line-height: 28px !important;
  color: #383838 !important;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 15px !important;
}

.inr-event-content h6.loc,a.attch {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px !important;
  position: relative;
  margin: 0;
}

.inr-event-content h6.loc a {
  color: #6e6e6e;
}

.inr-event-content h6.loc span {
  margin-left: 20px;
}

.inr-event-content h6.loc span i {
  color: #000000;
}
 

.inr-news-blk h6.date {
  font-size: 29px;
  text-align: center;
  font-weight: 600;
  margin: 0px 0px;
  color: #ffffff;
  background: linear-gradient(to right, #068fdb, #002f49);
  padding: 7px 20px;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inr-news-blk h6.date span {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
}
.inr-news-blk {
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 25%);
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.gal_new article {
  position: relative;
  box-shadow: 0px 0px 9px 0px #b3b3b3;
  border-radius: 5px;
}

.gal_new .total-img {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  background: #e1e1e1b5;
}

.gal_new .total-img {
  display: flex;
  padding: 0px 8px;
  border-radius: 10px;
}

.gal_new .total-img p {
  margin-bottom: 0;
  margin-left: 3px;
  background: linear-gradient(to right, #fcb045, #cd1616);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border-radius: 50%;
  font-size: 12px !important;
  position: absolute;
  left: 0;
  bottom: 2px;
  padding: 0PX;
}

.gal_new .total-img i {
  font-size: 25px;
}

.gal_new .gal-content {
  padding: 15px 15px;
}
h4.catgry-name {
  color: #232323;
  font-size: 19px; 
  margin: 0 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  font-weight: 500; 
}

.gal-content p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: black !important; 
  line-height:30px !important;
  text-align: left !important; 
  padding: 0;
}

.gal_new article img {
  width: 100%;
  height: 180px;
  object-fit: contain;
  background: #f1f1f1;
  margin: auto;
  display: block;
  float: inherit;
  padding-left: 0px;
}

.gal_new h6 {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 8px !important;
  position: relative;
  margin-top: 10px;
}

.gal_new h6 a {
  color: #fff !important;
  font-size: 13px;
  cursor: pointer;
}

.gal_new h6 span { 
   float: right;
   background: linear-gradient(to right, #09a3e7, #222882);
   padding: 0 10px;
}
.gal_new {
  margin-bottom: 20px;
}
.gal_new h6.date span i {
  color: #ffffff;
}
.inr-news-content {
  padding: 10px 15px;
  word-break: break-word;
  position: relative;
}

.inr-news-content h3 {
  color: #232323;
  font-size: 18px;
  line-height: 28px;
  display: block;
  margin-bottom: 5px !important;
  font-weight: 500;
  position: relative;
  margin: 0;
  text-transform: uppercase;
}

.inr-news-content p {
  line-height: 27px !important;
  color: #383838 !important;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 15px !important;
}

.inr-news-content h6.loc {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 8px !important;
  position: relative;
  margin: 0;
}
.sub-gallery {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  margin-bottom: 20px;
}

.sub-gallery>div {
  width: 25%;
}

.sub-gallery>div {
  color: #000;
  font-size: 16px;
  padding: 10px;
  border-right: 1px solid #bbbbbb;
}

.sub-gallery>div:nth-child(04) {
  border-right: unset;
}
.zoompic img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background: #f1f1f1;
  box-shadow: 0px 0px 7px 4px #ccc;
  border: 10px solid #fff;
  margin-bottom: 15px;
  border-radius: 3px;
  margin-top: 10px;
}
.discription h3 {
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 600;
} 
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #022939;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.msgimg{
  height: 300px;
  object-fit: contain;
  border: 3px double #ccc;
  padding: 5px;
  border-radius: 7px;
  margin-left: 20px;
  
  background: #f1f1f1;
}
.clearfix p.name {color: #333;font-weight: bold;}
.clearfix p.name span {
  display: block;
  font-weight: bold;
  color: #b29a1c;
}

.video-gallery a {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.video-gallery a img {
  height: 180px;
  width: 100%;
  background: #e3e9ed;
  object-fit: contain;
}

.video-gallery a h3 {
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 26px !important;
  text-transform: capitalize;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
}

.video-gallery {
  padding: 10px;
  box-shadow: 0px 0px 10px 2px #0000001a;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #fff;
}

.video-gallery a p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px; 
  text-align: center;
}
.chooseyear {
  float: right;
  margin-bottom: 20px;
}
.clr{clear: both;}
.topper-service p { 
  margin-bottom: 0; 
}
.services-style-current-theme {
  box-shadow: 1px 20px 20px -18px rgb(0 0 0 / 11%);
  height: 324px;
  border: solid 1px #efefef;
  margin-bottom: 30px;
  border-radius: 8px;
}

.services-style-current-theme .topper-service .thumb {
  position: relative;
  margin-bottom: 25px;
  overflow: hidden
}
 
.services-style-current-theme:hover .topper-service .thumb::before {
  -webkit-animation: shine .85s;
  animation: shine .85s
}   
@keyframes shine {
  100% {
      left: 125%
  }
}
  
.services-style-current-theme .topper-service .thumb img {
  object-fit: contain;
  background: #f8f8f8;
  width: 100%;
  height: 150px;
}
 
.services-style-current-theme .topper-service .thumb::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg)
}

.text-theme-colored3 {
  color: #ffc700 !important;
}

.font-weight-800 {
  font-weight: 800 !important
} 

.topper-service .title {
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 0;
}

.services-style-current-theme:hover .topper-service .thumb::before {
  -webkit-animation: shine .85s;
  animation: shine .85s
}
  
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tc {
  width: 49%;
  margin-bottom: 30px;
}

.tcblock iframe {
  width: 49%;
}

.tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.tc label p {
  margin-bottom: 0px;
}

.tc .form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
}

.viewdetailsbtn {
  background: #00adef;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
}

.viewdetailsbtn:hover {
  background: rgb(34 40 129) linear-gradient(160deg, #00adef 0%, #232882 50%, #00adef 100%) repeat scroll 0 0;
}

.years {
  min-height: 250px;
  position: relative;
}

.showYear {
  display: flex;
}

.month-selection select {
  padding: 10px 8px;
  border: 0px;
  width: 95%;
  margin-left: 10px;
  min-width: 150px;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.searchBox input {
  padding: 2px 4px;
  background: #ffffff;
  width: 95%;
  margin-left: 10px;
  padding-right: 0px;
  border: solid 1px #c3c3c3;
}

.tabs-dynamic {
  margin-bottom: 40px;
  background: #ededed;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  background: #f1f1f1;
  padding: 10px 0px;
}
.innerbody p {
  font-size: 15px;
  line-height: 30px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  color: #20488a;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.formbox {
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 30px 50px rgb(0 0 0 / 10%);
  display: flex;
  margin-bottom: 60px;
  border-radius: 4px;
}
.formboxright {
  width: 70%;
  padding: 50px 20px 50px 20px;
  background: #2a4a8c29;
  border-radius: 0px 5px 5px 0px;
}

.formboxleft {
  width: 30%;
  background: #435f99;
  padding: 50px 20px;
  display: flex;
  border-radius: 5px 0px 0px 5px;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.addressimg {
  float: left;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.addressdesc a {
  color: #fff;
  font-size: 14px;
  line-height: 33px; 
  word-break: break-all;
}

.addressdesc {
  margin-bottom: 20px;
  clear: both;
}
.form-group {
  margin-bottom: 15px;
  width: 48%;
  margin-right: 17px;
}
.formdiv {
  display: flex;
  flex-wrap: wrap;
}
.form-group label {
  margin-bottom: 10px;
}
.formboxleft h5 {
  font-size: 30px;
  color: #fff; 
  margin-bottom: 30px;
  text-align: center;
  line-height: 40px;
}
input[type="submit"] {
  transition: all 0.3s ease-in;
  padding: 10px 30px;
  border: 0px solid #ccc;
  background: linear-gradient(45deg, #5d1010, #be973e);
  color: #fff;
  border-radius: 4px;
  margin-top: 10px;
}
.form-group.textarea {
  width: 100%;
}
 
.form-group.textarea textarea {
  resize: none;
}
.error {
  color: red;
  font-size: 12px;
}
.formdiv span ,.careerform-feilds span{
  color: red;
}
.mainlist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
}
.mainlist li {
  font-size: 16px;
  line-height: 30px;
  list-style: none;
  margin: 5px 0;
  padding-left: 29px;
  position: relative;
}
.mainlist li:before {
  background: radial-gradient(#fff,#e7bb3e,#fff);
  border-radius: 50%;
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 15px;
}
.erp_sec p {
  line-height: 40px!important;
  margin-bottom: 0;
}
.mobileappimg img {
  width: 310px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.guideheading i {
  font-size: 6px;
  position: relative;
  top: -3px;
}
h3.Subhead {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 15px;
}
@media(max-width: 1600px){
  button.header-btn{
    min-width: 130px;
  }
}
@media(max-width: 1400px){
  .form-group { 
    width: 47%;
  }
  img.mobile-ftr {
    width: 120px;
}
.mobile-app p {
  font-size: 13px; 
}
.mobile-app .mobile-link img {
  margin: 5px 10px;
  height: 42px;
  width: auto;
}
.msg-anim2 { 
  width: 65px; 
}
.msg-anim4 { 
  width: 91px; 
}
.msg-anim3 { 
  width: 80px; 
}
.msg-anim1 { 
  left: 3%; 
  width: 57px;
}
.newsbg marquee {
  height: 290px;
}
button.header-btn {
  padding: 6px 17px;
  min-width: 114px;
  
}
.highlights:before { 
  width: 86%;}
  .logo img {
    width: 90%; 
}
.header-nav .online-fee button { 
  padding: 6px 14px; 
  font-size: 14px; 
}
nav.navbar ul.navbar-nav { 
  font-size: 14px;
}
button.header-btn span{
  font-size: 13px;
}
.header-contact img {
  width: 25px;
}
img.msg-anim4, img.msg-anim3, img.msg-anim2, .msg-anim1 {
  z-index: -1;
}
.header-contact p{
  font-size: 13px;
}
}
@media(max-width: 1200px){
  .col-lg-hide{
    display: none !important;
  }
  footer .d-flex li img {
    width: 19px;
}
footer .d-flex h5 { 
  font-size: 14px;
  text-align: center;
}
.ftr-cont.d-flex {
  flex-direction: column;
  align-items: center;
  margin-top: 20px

}
.about img {
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.about {
  text-align: center;
}
.col-lg-d-none{
  display: none;
}
.logo img{
  width: 100%;
}
}
@media(max-width: 991px) { 
  nav.navbar .nav-link {
    padding: 0;
}
ul.dropdown-menu.fade-up li {
  padding: 5px !important;
}
ul.dropdown-menu.fade-up.show a{
  padding: 5px !important;
}
  .formboxleft {
    width: 100%;
    border-radius: 5px 5px 0px 0px;
  }
  .formboxright {
    width: 100%;
    border-radius: 0px 0px 5px 5px;
  }
  .sub-gallery>div {
    width: 100%;
    border-right: 0;
}
.form-group {
  width: 100%;
}
  footer:before{  
    background: linear-gradient(0deg, #161c70 95%, transparent 10%);
    z-index: -1;
}

  nav.navbar {
      width: auto;
      position: static;
  }
  
  nav.navbar button.navbar-toggler {
      background: var(--dark-gradient);
      color: #fff;
  }
  
  nav.navbar button.navbar-toggler span {
      filter: invert(1);
  }
  
  div#main_nav {
      position: absolute;
      right: 0;
      width: 100%;
      top: 17%;
  }
  div#main_nav ul {
    align-items: flex-start;
    padding: 15px;
    position: static;
    background: #fff;
    border-radius: 15px;
  }

  div#main_nav ul li {
    padding: 9px 0;
    border-bottom: 1px dashed #ddd;
    width: 100%;
  }
}

@media(max-width: 767px){
  .message-blk { 
    padding: 30px 30px 100px;
    width: 100%;
    height: auto;
}
  .message-container { 
    flex-wrap: wrap;
}
  .message-container .messageimg {
    width: 100%;
  }
  .message-container .messagebox {
    width: 100%;
}
  .msgimg { 
    margin-left: 0;
  }
  .tc {
    width: 100%;
  }
  .tcblock iframe {
    width: 100%;
}
  .ftr-cont.d-flex {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px ;
    justify-content: center;
    margin-bottom: 20px;
}
  .homeeventbox{
    margin: auto;
    width: 90%;
  }
  .aboutmidsec .box {
    margin-top: 0;
}
.mt-xs-5{
  margin-top: 40px;
}
.mobile-app {
  justify-content: center;
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.logo img {
  max-width: 330px;
}
div#main_nav { 
  top: 26%;
}
}
@media(max-width: 600px){
  footer { 
    padding: 80px 0px 20px;
  }
  .aboutmidsec{
    flex-direction: column;
  }
  .aboutmidsec .box{
    width: 100%;
    margin: 10px 0;
  }
  .message-blk{
    width: 100%;
  }
  .message-container {
    flex-direction: column;
}
.highlights:before, .header-contact:before{
  transform: unset;
  right: 0;
  width: 100%;
}

.message-container> div {
    width: 100% !important;
}
.message-blk {
  width: 100%;
  background: var(--light-color);
  margin-bottom: 20px;
}
.mobile-app {
  flex-direction: column-reverse;
  align-items: center;
}
.message-blk { 
  background-size: 100% 100%;
  padding: 30px 14px 31px; 
}
}